section.features-section_hr_001 {
    position: relative;
    padding: 70px 0 40px;
}

.feature-block-one .inner-box {
    position: relative;
    margin-bottom: 30px;
    border: 1px solid #e5e5e5;
    padding: 30px;
    padding-left: 100px;
    width: 100%;
    transition: all 500ms ease;
}
.feature-block-one .inner-box:hover{
    border-color: #e35711;
}
.feature-block-one .icon {
    position: absolute;
    left: 30px;
    top: 30px;
    font-size: 45px;
    color: var(--theme-color);
}
.feature-block-one h4 {
    line-height: 35px;
    color: #141417;
    margin-bottom: 5px;
}
.feature-block-one .text {
    color: #808080;
}


/* Slideshow container */
.slideshow-container {
    width: 100%;
    position: relative;
    margin: auto;
  }
  .dot {
    position: absolute;
    bottom: 0;
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
  }
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }
  @keyframes fade {
    from {opacity: .4} 
    to {opacity: 1}
  }
  


/* featured 3 */
.featured-three{
    padding: 90px 0;
}
.featured-three .product-block-one{
    margin-bottom: 24px;
}
.featured-three .product-block-one img{
    width: 176px;
    height: 168px;
}
/* featured 3 */