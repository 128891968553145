.pagination{
    position: relative;
    display: block;
}  
.pagination li{
    position: relative;
    display: inline-block;
    margin: 0px 3.5px;
    font-size: 16px;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    color: #141417;
}  
.pagination li:last-child{
    margin: 0px !important;
}  
.pagination li a{
    position: relative;
    display: inline-block;
    font-size: 16px;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    color: #141417;
    z-index: 1;
    border: 1px solid #e5e5e5;
    transition: all 500ms ease;
}  
.pagination li a:hover,
.pagination li a.current{
    color: #fff;
}  
.pagination li.dot a{
    border: none;
}
.pagination li a:hover, 
.pagination li a.current {
    background: #e25712;
    border-color: #e25712;
}