
/** Search Popup **/
.search-popup {
    position: fixed;
    left: 0;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 99999;
    visibility: hidden;
    opacity: 0;
    overflow: auto;
    background: rgba(0, 0, 0, 0.80);
    padding-bottom: 200px;
    -webkit-transform: translateY(101%);
    -ms-transform: translateY(101%);
    transform: translateY(101%);
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
}
.search-popup.popup-visible {
    -webkit-transform: translateY(0%);
    -ms-transform: translateY(0%);
    transform: translateY(0%);
    visibility: visible;
    opacity: 1;
}
.search-popup .popup-inner{
  width: 100%;
  background: #fff;
}
.search-popup .upper-box{
  position: relative;
  padding: 70px 70px;
}
.search-popup .overlay-layer {
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    display: block;
}
.search-popup .close-search {
    position: relative;
    font-size: 22px;
    color: #141417;
    cursor: pointer;
    z-index: 5;
    top: 11px;
    width: 20px;
    transition: all 500ms ease;
}
.search-popup .close-search:hover {
    color: red;
}
.search-popup .search-form {
    position: relative;
    width: 100%;
    padding: 100px 0px 250px 0px;
}
.search-popup .search-form .form-group{
  position: relative;
  margin: 0px;
}
.search-popup .search-form fieldset input[type="search"] {
    position: relative;
    height: 90px;
    padding: 20px 0px;
    background: #ffffff;
    line-height: 30px;
    font-size: 24px;
    color: #808080;
    font-family: 'Inter', sans-serif;
    border: none;
    border-radius: 0px;
    padding-right: 50px;
    border-bottom: 1px solid #e5e5e5;
}
.search-popup .search-form fieldset button[type="submit"] {
    position: absolute;
    top: 30px;
    right: 0px;
    font-size: 22px;
    color: #141417;
    cursor: pointer;
    transition: all 500ms ease;
}
.search-popup .search-form fieldset input[type="search"]:focus{
  border-color: #141417;
}
.search-popup .form-control:focus{
  box-shadow: none !important;
}
.search-box-outer{
  cursor: pointer;
  font-size: 20px;
  margin-top: 5px;
}

/* popup two */
#newspop{
  position: fixed;
  width: 100%;
  height: 100vh;
  /* display: none; */
  background: rgba(0, 0, 0, 0.6);
  z-index: 999999;
}
/* popup two */

/* opoup menu */
#menupop {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: none;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999;
}
.search-menu i{
  font-size: 36px;
  font-weight: 500;
  color: #E7131A;
  cursor: pointer;
}
.menupop-container {
  position: relative;
  max-width: 320px;
  height: 100%;
  background: #ffffff;
}
.menupop-header{
  padding-top: 20px;
  padding-left: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.menupop-close {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  background: #F1F3F5;
  position: absolute;
  top: 25px;
  right: 20px;
  cursor: pointer;
  z-index: 99999;
  transition: 0.5s;
  transform: rotate(45deg);
}
.menupop-close:hover{
  background: #E7131A;
}
.menupop-close:hover i{
  color: #ffffff;
}

.menupop-sidebar > ul{
  margin-top: 50px;
  width: 100%;
  background: #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.menupop-sidebar > .menupop-dropdown {
  margin-top: 20px;
}
.menupop-sidebar > ul h6{
  display: inline-block;
  padding-left: 25px;
  color: #8A8A8A;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
}
.menupop-sidebar > ul>li {
    position: relative;
    width: 100%;
}
.menupop-sidebar > ul>li:last-child {
    border-bottom: none;
}
.menupop-sidebar > ul>li:before {
    position: absolute;
    content: '';
    right: 0px;
    top: 0px;
    width: 0%;
    height: 100%;
    display: block;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}
.menupop-sidebar > ul>li>a {
    position: relative;
    display: block;
    padding: 17px 25px;
    border-bottom: 1px solid #e9e5df;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: #141417;
    font-family: 'Inter', sans-serif;
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}
.menupop-sidebar > ul>li>a.active, .menupop-sidebar > ul>li> ul>li>a.active{
  color: #E7131A;
}
.menupop-sidebar > ul>li:last-child>a{
    border-bottom: none;
}
.menupop-sidebar > ul>li.dropdown>a:after {
    position: absolute;
    content: "\f105";
    right: 15px;
    top: 18px;
    display: block;
    line-height: 24px;
    font-size: 17px;
    /* font-family: 'Font Awesome 5 Pro'; */
    font-family: 'fontawesome';
    font-weight: 400;
}
.menupop-sidebar > ul>li>a i,
.menupop-sidebar > ul>li>a i {
    margin-right: 10px;
    font-size: 23px;
    vertical-align: middle;
}
.menupop-sidebar > ul>li>ul{
    position: absolute;
    left: 100%;
    top: 15px;
    width: 230px;
    background: #fff;
    box-shadow: 0 0px 30px 0px rgba(0,0,0,0.06);
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
}
.menupop-sidebar > ul>li:hover>ul{
    visibility: visible;
    opacity: 1;
    top: 0px;
}
.menupop-sidebar > ul>li> ul>li>a {
    position: relative;
    display: block;
    padding: 17px 25px;
    border-bottom: 1px solid #e9e5df;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: #141417;
    font-family: 'Inter', sans-serif;
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}
.menupop-sidebar > ul>li> ul>li:last-child>a{
    border-bottom: none;
}
.menupop-sidebar > ul>li> ul>li>a i,
.menupop-sidebar > ul>li> ul>li>a i {
    margin-right: 10px;
    font-size: 23px;
    vertical-align: middle;
}
.menupop-sidebar > a{
    background: #2B313E;
}  
.menupop-sidebar > ul>li>a:hover{
    color: #E7131A;
}
.menupop-sidebar > ul>li> ul>li>a:hover{
    color: #E7131A;
}
/* opoup menu */

/* ======================================
responsive
========================================= */
@media only screen and (min-width: 360px) and (max-width: 740px){
  .menupop-header-logo {
    width: 80px;
  }
  .menupop-container {
    max-width: 184px;
  }
  .menupop-close {
    width: 30px;
    height: 30px;
  }
  .menupop-sidebar > ul>li>ul {
    width: 166px;
  }
}
/* ======================================
responsive
========================================= */
