/** mobile-menu **/
.nav-outer .mobile-nav-toggler{
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    color:#3786ff;
    display: none;
}
.mobile-menu{
    position: relative;
    right: 0;
    top: 0;
    width: 300px;
    padding-right:30px;
    max-width:100%;
    height: 100%;
    /* opacity: 0; */
    visibility: visible;
    z-index: 999999;
    transition: all 900ms ease;
}
.mobile-menu .navbar-collapse{
    display:block !important; 
}
.mobile-menu .nav-logo {
    position: relative;
    padding: 9px 25px;
    text-align: left;
    padding-bottom: 60px;
}
.mobile-menu-visible{
    z-index: 999999;
    /* overflow: hidden; */
}
.mobile-menu-visible .mobile-menu{
    opacity: 1;
    visibility: visible;
}

.mobile-menu .menu-backdrop{
    position: fixed;
    left: 0%;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 900ms ease;
    background-color: #333;
}
.mobile-menu-visible .mobile-menu .menu-backdrop{
    opacity: 0.70;
    visibility: visible;
    right: 100%;
    -webkit-transition: all .8s ease-out 0s;
    -o-transition: all .8s ease-out 0s
}
.mobile-menu .menu-box{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    /* overflow-y: auto; */
    background: #eee;
    padding: 0px 0px;
    z-index: 999;
    /* opacity: 0; */
    /* visibility: hidden; */
    border-radius: 0px;
    /* -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    transition: all 900ms ease !important; */
}
.mobile-menu-visible .mobile-menu .menu-box{
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.7s ease;
    -o-transition: all 0.7s ease;
    transition: all 0.7s ease;
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}
.mobile-menu .close-btn{
    position: absolute;
    right: 0px;
    top: 0px;
    line-height: 30px;
    width: 24px;
    text-align: center;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
    z-index: 10;
    -webkit-transition:all 0.9s ease;
    -moz-transition:all 0.9s ease;
    -ms-transition:all 0.9s ease;
    -o-transition:all 0.9s ease;
    transition:all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn{
    -webkit-transform:rotate(360deg);
    -ms-transform:rotate(360deg);
    transform:rotate(360deg);
}
.mobile-menu .close-btn:hover{
    -webkit-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    transform:rotate(90deg);
}
.mobile-menu .navigation{
    position: relative;
    display: block;
    width: 100%;
    float: none;
}
.mobile-menu .navigation li{
    position: relative;
    display: block;
    border-top: 1px solid rgba(255,255,255,0.10);
}
.mobile-menu .navigation:last-child{
    border-bottom: 1px solid rgba(255,255,255,0.10);
}
.mobile-menu .navigation li > ul > li:first-child{
    border-top: 1px solid rgba(255,255,255,0.10);
}
.mobile-menu .navigation li > a{
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 25px;
    font-size: 15px;
    font-weight: 500;
    color: #ffffff;
    text-transform: uppercase;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; 
}
.mobile-menu .navigation li ul li > a{
    font-size: 16px;
    margin-left: 20px;
    text-transform: capitalize;
}
.mobile-menu .navigation li > a.active:before{
    content:'';
    position:absolute;
    left:0;
    top:0;
    height:0;
    border-left:5px solid #fff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; 
}
.mobile-menu .navigation li.current > a:before{
    height:100%;
}
.mobile-menu .navigation li.dropdown .dropdown-btn{
    position:absolute;
    right:6px;
    top:6px;
    width:32px;
    height:32px;
    text-align:center;
    font-size:16px;
    line-height:32px;
    color:#ffffff;
    background:rgba(255,255,255,0.10);
    cursor:pointer;
    border-radius:2px;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; 
    z-index:5;
}
.mobile-menu .navigation li.dropdown .dropdown-btn.open{
    color: #ffffff;
    -webkit-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    transform:rotate(90deg);  
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul,
.mobile-menu .navigation > li.dropdown > .megamenu{
    display: none;
}
.mobile-menu .social-links{
    position:relative;
    padding:0px 25px;
}
.mobile-menu .social-links li{
    position:relative;
    display:inline-block;
    margin:0px 10px 10px;
}
.mobile-menu .social-links li a{
    position:relative;
    line-height:32px;
    font-size:16px;
    color:#ffffff;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease; 
}

div#mCSB_1_container{
    top: 0px !important;
}
.mobile-menu .contact-info {
    position: relative;
    padding: 120px 30px 20px 30px;
}
.mobile-menu .contact-info h4 {
    position: relative;
    font-size: 20px;
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 20px;
}
.mobile-menu .contact-info ul li {
    position: relative;
    display: block;
    font-size: 15px;
    color: rgba(255,255,255,0.80);
    margin-bottom: 3px;
}
.mobile-menu .contact-info ul li a{
    color: rgba(255,255,255,0.80);
}  
.mobile-menu .contact-info ul li a:hover{
    text-decoration: underline;
}
.mobile-menu .contact-info ul li:last-child{
    margin-bottom: 0px;
}