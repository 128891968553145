



/*** use-font ***/
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


* {
  margin:0px;
  padding:0px;
  border:none;
  outline:none;
}

body {
  font-size:16px;
  color:#8B929A;
  line-height:28px;
  font-weight:400;
  background:#ffffff;
  font-family: 'Rubik', sans-serif;
  background-size:cover;
  background-repeat:no-repeat;
  background-position:center top;
  -webkit-font-smoothing: antialiased;
}

.stickySidebar {
  position: sticky;
  top: 0;
  align-self: flex-start;
  z-index: 10;
}

@media (min-width:1200px) {
  .container {
    max-width: 1200px;
    padding: 0px 15px;
  }
}

.large-container{
  max-width: 1500px;
  padding: 0px 15px;
  margin: 0 auto;
}

.container-fluid{
  padding: 0px;
}

.auto-container{
  position:static;
  /* max-width:1200px; */
  padding:0px 25px;
  /* margin:0 auto; */
}

.small-container{
  max-width:680px;
  margin:0 auto;
}

.boxed_wrapper{
  position: relative;
  margin: 0 auto;
  /* overflow: hidden !important; */
  /* height: 100vh; */
  width: 100%;
  min-width: 300px;
}

a{
  text-decoration:none;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}

a:hover{
  text-decoration:none;
  outline:none;
}

input,button,select,textarea{
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: transparent;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

ul,li{
  list-style:none;
  padding:0px;
  margin:0px; 
}

input{
  transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus{
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}

p{
  position: relative;
  font-family: 'Rubik', sans-serif;
  color: #8B929A;
  font-size: 16px;
  line-height: 29px;
  margin-bottom: 0px;
  font-weight: 400;;
  transition: all 500ms ease;
}

p:last-child{
  margin-bottom: 0px;
}

h1,h2,h3,h4,h5,h6{
  position:relative;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  color: #2B313E;
  line-height: 1.4em;
  margin: 0px;
  transition: all 500ms ease;
}

/** use-font-family **/
.font_family_inter{
  font-family: 'Rubik', sans-serif;
}

.centred{
  text-align: center;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}


figure{
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

/** button **/

/* theme-btn-one */
.theme-btn{
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 15px;
  line-height: 26px;
  font-weight: 600;
  font-family: 'Rubik', sans-serif;
  color: #fff !important;
  text-align: center;
  padding: 12px 32px;
  border-radius: 5px;
  text-transform: capitalize;
  z-index: 1;
  -webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
  -o-transition: all cubic-bezier(.4,0,.2,1) .4s;
  transition: all cubic-bezier(.4,0,.2,1) .4s;
}

.theme-btn i{
  position: relative;
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  top: 1px;
}

.theme-btn-one:before{
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.theme-btn-one:hover:before{
  width: 100%;
}

/* theme-btn-two */

.theme-btn-two{
  background: #fff;
  padding: 12px 62px 12px 30px;
}

.theme-btn-two:hover{
  padding: 12px 69px 12px 30px;
}

.theme-btn-two span{
  position: relative;
  color: transparent;
}

.theme-btn-two span:before,
.theme-btn-two span:after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) translateX(0);
  -ms-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
  width: 100%;
  -webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
  -o-transition: all cubic-bezier(.4,0,.2,1) .4s;
  transition: all cubic-bezier(.4,0,.2,1) .4s;
}

.theme-btn-two span:before{
  color: #141417;
}

.theme-btn-two span:after{
  color: #f4244f;
  opacity: 0;
}

.theme-btn-two:hover span:before {
  -webkit-transform: translateY(-50%) translateX(-30px);
  -ms-transform: translateY(-50%) translateX(-30px);
  transform: translateY(-50%) translateX(-30px);
  opacity: 0;
}

.theme-btn-two:hover span:after {
  -webkit-transform: translateY(-50%) translateX(0);
  -ms-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
  opacity: 1;
}

.theme-btn-two:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 18px;
  height: 2px;
  background: #141417;
  transition: all 500ms ease;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.theme-btn-two:hover:before {
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 25px;
}

.theme-btn-two:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  width: 9px;
  height: 9px;
  border-right: 2px solid #141417;
  border-top: 2px solid #141417;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

/* theme-btn-three */
.theme-btn-three{
  padding: 12px 62px 12px 30px;
}

.theme-btn-three:hover{
  padding: 12px 69px 12px 30px;
}

.theme-btn-three span{
  position: relative;
  color: transparent;
}

.theme-btn-three span:before,
.theme-btn-three span:after {
  content: attr(data-text);
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%) translateX(0);
  -ms-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
  width: 100%;
  transition: all 500ms ease;
}

.theme-btn-three span:before{
  color: #fff;
}

.theme-btn-three span:after{
  color: #141417;
  opacity: 0;
}

.theme-btn-three:hover span:before {
  -webkit-transform: translateY(-50%) translateX(-30px);
  -ms-transform: translateY(-50%) translateX(-30px);
  transform: translateY(-50%) translateX(-30px);
  opacity: 0;
}

.theme-btn-three:hover span:after {
  -webkit-transform: translateY(-50%) translateX(0);
  -ms-transform: translateY(-50%) translateX(0);
  transform: translateY(-50%) translateX(0);
  opacity: 1;
}

.theme-btn-three:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 18px;
  height: 2px;
  background: #fff;
  transition: all 500ms ease;
  -webkit-transform-origin: right;
  -ms-transform-origin: right;
  transform-origin: right;
}

.theme-btn-three:hover:before {
  -webkit-transform: translateY(-50%) scaleX(1);
  -ms-transform: translateY(-50%) scaleX(1);
  transform: translateY(-50%) scaleX(1);
  width: 25px;
}

.theme-btn-three:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 32px;
  width: 9px;
  height: 9px;
  border-right: 2px solid #fff;
  border-top: 2px solid #fff;
  -webkit-transform: translateY(-50%) rotate(45deg);
  -ms-transform: translateY(-50%) rotate(45deg);
  transform: translateY(-50%) rotate(45deg);
  transition: all 500ms ease;
}

/** theme-btn-four **/

.theme-btn-four{
  border-radius: 30px;
  background: #6a36ff;
}

.theme-btn-four:before{
  background-color: #141417;
  border-radius: 30px;
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: .3s transform ease-out;
  will-change: transform;
  z-index: -1;
}

.theme-btn-four:hover:before{
  transform: translate(0, 0);
}

/** theme-btn-five **/

.theme-btn-five{
  background: #612ade;
  padding: 17px 40px;
}

.theme-btn-five:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.theme-btn-five:hover:before{
  width: 100%;
}

/** theme-btn-six **/

.theme-btn-six{
  border: 1px solid #e7e7e7;
  padding: 16px 40px;
  color: #141417 !important;
}

.theme-btn-six:hover{
  color: #fff !important;
  border-color: #f1592a;
}

.theme-btn-six:before{
  position: absolute;
  content: '';
  background: #f1592a;
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.theme-btn-six:hover:before{
  width: 100%;
}

/** theme-btn-seven **/

.theme-btn-seven{
  background: #f1592a;
  border-radius: 30px;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Muli', sans-serif;
  padding: 17px 35px;
  letter-spacing: 1px;
}

.theme-btn-seven:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 0%;
  height: 100%;
  top: 0px;
  right: 0px;
  border-radius: 30px;
  z-index: -1;
  transition: all 500ms ease;
  
}

.theme-btn-seven:hover:before{
  width: 100%;
  left: 0px;
}

/** theme-btn-eight **/
.theme-btn-eight{
  font-weight: 500;
  font-family: 'Spartan', sans-serif;
  border-radius: 0px;
  padding: 13px 26px 11px 26px;
  background: #E7131A;
}

.theme-btn-eight:before{
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  position: absolute;
  width: 200%;
  height: 200%;
  content: "";
  bottom: 110%;
  left: 50%;
  background-color: #87161a;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 50%;
  z-index: -1;
}

.theme-btn-eight:hover:before{
  bottom: -40%;
}

/* theme-btn-nine */
.theme-btn-nine{
  border-radius: 0px;
  font-family: 'Jost', sans-serif;
  padding: 17px 40px;
  font-size: 17px;
  background: #fff;
  color: #141417 !important;
}

.theme-btn-nine:before{
  position: absolute;
  content: '';
  background: #141417;
  width: 100%;
  height: 0%;
  left: 0px;
  bottom: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.theme-btn-nine:hover:before{
  top: 0px;
  height: 100%;
}

.theme-btn-nine:hover{
  color: #fff !important;
}

.sec-pad{
  padding: 150px 0px;
}


/** sec-title **/
.sec-title .sub-title{
  border-radius: 30px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.10);
  padding: 6px 37px;
  text-transform: uppercase;
}

.sec-title .sub-title:before{
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  border-radius: 30px;
  z-index: -1;
}

.sec-title h2{
  line-height: 52px;
}

.sec-title-two span{
  color: #fff;
  border-radius: 30px;
  text-transform: uppercase;
}

.sec-title-two h2{
  line-height: 52px;
}

.sec-title-two.light h2{
  color: #fff;
}

.sec-title-three h6{
  text-transform: uppercase;
}

.sec-title-three h2{
  line-height: 52px;
}

.sec-title-three.light h2{
  color: #fff;
}

.sec-title-four h6{
  border: 1px solid #e5e5e5;
  padding: 0px 28px;
}

.sec-title-four h2{
  line-height: 52px;
}

.sec-title-five.light h2{
  color: #fff;
}

.sec-title-seven h4{
  padding-right: 14px;
}

.sec-title-seven h4:after{
  position: absolute;
  content: '/';
  top: 0px;
  right: 0px;
  font-size: 16px;
}

.sec-title-seven.centred h4{
  padding-left: 14px;
}

.sec-title-seven.centred h4:before{
  position: absolute;
  content: '/';
  top: 0px;
  left: 0px;
  font-size: 16px;
}

.sec-title-seven h2{
  line-height: 55px;
}

.sec-title-nine h6{
  background: #feecee;
}

.sec-title-ten .sub-title{
  background: #e9e3f6;
  border-radius: 25px;
}


@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}


@keyframes slide{
  from { background-position: 0 0; }
    to { background-position: 1920px 0; }
}

@-webkit-keyframes slide{
  from { background-position: 0 0; }
    to { background-position: 1920px 0; }
}


.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.float-bob-x{
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

:root {
  --theme-color: #E7131A;
}
.theme-color {
  color: var(--theme-color);
}
.color_black {
  color: #141417;
}
.g_color_2 {
  color: transparent;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
}
.g_color_2 {
  background: -webkit-linear-gradient(45deg, #41a0ea, #f18e95 75%);
}
.default-form input[type='text'],
.default-form input[type='email'],
.default-form input[type='tel'],
.default-form input[type='password'],
.default-form textarea{
  position: relative;
  width: 100%;
  height: 50px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  font-size: 15px;
  color: #808080;
  padding: 15px 20px;
  transition: all 500ms ease;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
@keyframes slide{
  from { background-position: 0 0; }
    to { background-position: 1920px 0; }
}

@-webkit-keyframes slide{
  from { background-position: 0 0; }
    to { background-position: 1920px 0; }
}
.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.float-bob-x{
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
.tabs-box .tab{
  position:relative;
  display:none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}
.tabs-box .tab.active-tab{
  display:block;  
}
.tabs-box .tab{
  transform:scale(0.9,0.9) translateY(0px);
}
.tabs-box .tab.active-tab{
  transform:scale(1) translateY(0px);
}
/* Color */
a:hover,
a {
  color: #E7131A;
}
.btn-style-one {
  background-color: #E7131A;
}
.banner-slider-button-next:hover {
  background-color: #E7131A;
}
.banner-slider-button-prev:hover {
  background-color: #E7131A;
}
.main-header .outer-box{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header-lower .outer-box .logo-box{
  padding: 30px 0px;
}
.header-lower .outer-box .logo-box .logo{
  max-width: 142px;
}
.header-lower .outer-box .logo-box .logo img{
  width: 100%;
}


/******* footer *********/
.footer-one{
  position: relative;
  background: #222226;
}
.footer-top{
  position: relative;
  padding: 30px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}
.footer-top .social-links li{
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.footer-top .social-links li:last-child{
  margin: 0px !important;
}

.footer-top .social-links li a{
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  font-size: 16px;
  color: #fff;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  text-align: center;
  z-index: 1;
}

.footer-top .social-links li a:before{
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  transform: scale(0,0);
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.footer-top .social-links li a:hover:before{
  transform: scale(1,1);
}

.footer-top .social-links{
  position: relative;
  top: 4px;
}

.footer-widget-section{
  position: relative;
  padding: 70px 0px 70px 0px;
}

.footer-widget .widget-title{
  position: relative;
  display: block;
  margin-bottom: 26px;
}

.footer-widget .widget-title h4{
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 0px;
}

.footer-widget p{
  color: #8B929A;
}

.footer-widget-section .about-widget p{
  margin-bottom: 32px;
}

.footer-widget-section .about-widget .subscribe-form .form-group{
  position: relative;
  margin: 0px;
}

.footer-widget-section .about-widget .subscribe-form .form-group input[type='email']{
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  background: #1c1c1f;
  border: 1px solid #1c1c1f;
  border-radius: 6px;
  font-size: 15px;
  color: #a9a9aa;
  padding: 0px 20px;
  transition: all 500ms ease;
}

.footer-widget-section .about-widget .subscribe-form .form-group button{
  position: absolute;
  top: 12px;
  right: 20px;
  font-size: 14px;
  background: transparent;
  color: #a9a9aa;
  transition: all 500ms ease;
}

.footer-widget-section .links-list li{
  position: relative;
  display: block;
  font-size: 16px;
  color: #a9a9aa;
  margin-bottom: 7px;
}

.footer-widget-section .links-list li:last-child{
  margin-bottom: 0px;
}

.footer-widget-section .links-list li a{
  display: inline-block;
  color: #a9a9aa;
}

.footer-widget-section .links-list li a:hover{
  color: #fff;
}

.footer-widget-section .info-list li{
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #a9a9aa;
  margin-bottom: 19px;
}

.footer-widget-section .info-list li:last-child{
  margin-bottom: 0px;
}

.footer-widget-section .info-list li a{
  display: inline-block;
  color: #a9a9aa;
}

.footer-widget-section .info-list li a:hover{
  color: #fff;
}

.footer-bottom{
  position: relative;
  background: #141417;
  padding: 31px 0px;
}

.footer-bottom p,
.footer-bottom a{
  color: #a9a9aa;
}

.footer-bottom a:hover{
  color: #fff;
}

.footer-bottom .footer-nav li{
  position: relative;
  display: inline-block;
  margin-right: 35px;
}

.footer-bottom .footer-nav li:last-child{
  margin-right: 0px;
}

.footer-bottom .footer-nav li:before{
  position: absolute;
  content: '';
  background: #9b9b9c;
  width: 1px;
  height: 16px;
  top: 7px;
  right: -18px;
}

.footer-bottom .footer-nav li:last-child:before{
  display: none;
}

.footer-one .pattern-layer .pattern-1{
  position: absolute;
  left: -30px;
  bottom: 50px;
  width: 205px;
  height: 272px;
  background-repeat: no-repeat;
  opacity: 0.03;
}

.footer-one .pattern-layer .pattern-2{
  position: absolute;
  left: 0px;
  bottom: 90px;
  width: 196px;
  height: 266px;
  background-repeat: no-repeat;
}

.footer-one .pattern-layer .pattern-3{
  position: absolute;
  top: -24px;
  right: -50px;
  width: 193px;
  height: 278px;
  background-repeat: no-repeat;
  opacity: 0.03;
}

.footer-one .pattern-layer .pattern-4{
  position: absolute;
  top: 0px;
  right: 0px;
  width: 196px;
  height: 266px;
  background-repeat: no-repeat;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}