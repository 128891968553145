.brand{
    background: #F7FAFF;
    padding: 90px 0;
}
.brand-container{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.05);
    
    text-align: center;
    margin-bottom: 24px;
    transition: 0.5s;
}
.brand-container::before{
    content: "";
    width: 40px;
    height: 100%;
    background: rgba(255,255,255,0.5);
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.5s;
}
.brand-container:hover::before{
    left: 100%;
}
.brand-logo a{
    display: inline-block;
    padding: 10px 0;
    width: 100%;
}
.brand-logo span{
    display: block;
    color: #2B313E;
    text-align: center;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    margin-top: 5px;
}