.containerAS {
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
  /* background-image: url("../../assets/images/banner/banner-4.jpg"); */
  background-size: cover;
  background-position: center;
  height: 95vh;
}

.profile {
  background-color: #ffffff;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%; /* Adjust max-width as needed */
}

.profile-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 80%;
}

.avatar {
  width: 120px;
  height: 120px;
  margin-right: 20px;
}

.profile-info {
  width: 100%;
  text-align: left;
  margin-bottom: 3rem;
}
.profile-info > p {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
}

.form {
    /* position: relative; */
    padding: 2rem;
    /* background: linear-gradient(14deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.7) 66%, rgb(0, 0, 0) 100%), radial-gradient(circle, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.2) 65%, rgba(0, 0, 0, 0.9) 100%); */
    /* border: 2px solid var(--theme-color); */
    overflow: hidden;
    /* z-index: +1; */
    /* border-radius: 15px; */
    /* min-height: 50vh; */
    height: 100%;
    /* width: 90%;
    max-width: 500px; */
}
.edit{
    display: flex;
    justify-content: center;
    align-items: center;
}
.tyre-image {
  width: 170px; /* Adjust size as needed */
  margin-top: 20px;
  margin-left: 20px;
  animation: rotateTyre 8s linear infinite; /* Animation */
}

@keyframes rotateTyre {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tyre-image2 {
  width: 220px; /* Adjust size as needed */
  margin-top: 20px;
  transform: scaleY(-1) rotate(180deg);
  rotate: 5deg;
}

@media screen and (min-width: 768px) {
  .containerAS {
    flex-direction: row;
    align-items: flex-end;
  }

  .profile {
    flex-direction: row;
    max-width: 70%; /* Adjust max-width as needed */
  }
}
.bg-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  background-size: cover;
  animation: animateBg 50s linear infinite;
}

@keyframes animateBg {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }
}

.star {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.1),
    0 0 0 8px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1);
  animation: animate 3s linear infinite;
}

.star::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 300px;
  height: 1px;
  background: linear-gradient(90deg, #fff, transparent);
}

@keyframes animate {
  0% {
    transform: rotate(315deg) translateX(0);
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    transform: rotate(315deg) translateX(-1000px);
    opacity: 0;
  }
}

.star:nth-child(1) {
  top: 0;
  right: 0;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 2s;
}

.star:nth-child(2) {
  top: 0;
  right: -220px;
  left: initial;
  animation-delay: 0.2s;
  animation-duration: 3s;
}

.star:nth-child(3) {
  top: 0;
  right: 220px;
  left: initial;
  animation-delay: 2.75s;
  animation-duration: 2.75s;
}

.star:nth-child(4) {
  top: 0;
  right: -220px;
  left: initial;
  animation-delay: 1.6s;
  animation-duration: 1.6s;
}