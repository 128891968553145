
/* Header Lower */
.ex_shop_header .header-lower {
    position: relative;
    background: #2B313E;
}
.header-style-two .header-lower {
    background: #E7131A;
}
.ex_shop_header .header-lower::before{
    content: '';
    max-width: 314px;
    width: 100%;
    height: 100%;
    /* background: rgb(33, 39, 52); */
    background-color: white;
    position: absolute;
}

.ex_shop_header.wholesalebar .header-lower::before{
  
    background-color: #D3D3D3 !important;
}
.ex_shop_header .header-lower .inner-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.ex_shop_header .header-lower .navbar-right-info {
    position: relative;
}
.ex_shop_header .header-lower .navbar-right-info ul{
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
}
.ex_shop_header .header-lower .navbar-right-info ul li{
    line-height: 18px;
}
.ex_shop_header .header-lower .navbar-right-info ul li .shopping-cart{
    position: relative;
}
.ex_shop_header .header-lower .navbar-right-info ul li .shopping-cart span{
    display: inline-block;
    position: relative;
    right: 10px;
    top: -23px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 50%;
    font-size: 12px;
    color: #fff;
    background: #E7131A;
}
.ex_shop_header .header-lower .navbar-right-info ul li i{
    font-size: 30px;
    color: #fff;
}
.ex_shop_header .header-lower .navbar-right-info ul li a{
    display: flex;
    gap: 8px;
    align-items: center;
    
}
.ex_shop_header .header-lower .navbar-right-info ul li a p{
    color: #fff;
    font-size: 10px;
    line-height: 12px;
}
.ex_shop_header .header-lower .navbar-right-info ul li a span{
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-transform: capitalize;
    color: #fff;
    margin-top: 2px;
}
.ex_shop_header .header-lower .menu-area{
    padding-left: 260px;
}
.ex_shop_header .header-lower .shop-category{
    position: absolute;
    left: 0px;
    top: 0px;
    max-width: 318px;
    width: 100%;
    height: 100%;
    transition: 0.5s;
}
.shop-category .sidebar-dropdown{
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}
.ex_shop_header .header-lower .shop-category:hover .sidebar-dropdown{
    visibility: visible;
    opacity: 1;
}
.ex_shop_header .header-lower .shop-category > a{
  position: relative;
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  height: 100%;
  color: #fff;
  margin: 0px;
  padding: 26px 20px 18px 25px;
  cursor: pointer;
}
.search-box{
  position: relative;
  max-width: 630px;
  width: 100%;
  padding: 26px 20px 18px 48px;
  background: #F5F5F5;
}
.ex_shop_header .header-upper .search-box {
    position: relative;
    /* width: 565px; */
    background: #fff;
}
.header-style-two .search-box{
    padding: 0;
}
.header-style-two .search-box form{
    padding: 18px 20px 16px 48px;
    position: relative !important;
}
.search-box input{
    width: 100%;
}
.search-box input::placeholder{
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 19px;
    color: #A4A4A4;
}
.search-box button{
    position: absolute;
    top: 0;
    right: 0;
    width: 66px;
    height: 100%;
    background: #E7131A;
}
.search-box button i{
    color: #fff;
    font-size: 25px;
}
.ex_shop_header .header-lower .shop-category > a>span {
    margin-right: 15px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    transform: rotateX(180deg);
    top: -3px;
}
.ex_shop_header .header-lower .shop-category > ul{
  position: absolute;
  left: 0px;
  top: 72px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0px 30px 0px rgba(0,0,0,0.06);
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.ex_shop_header .header-lower .shop-category > ul>li {
    position: relative;
    width: 100%;
}
.ex_shop_header .header-lower .shop-category > ul>li:last-child {
    border-bottom: none;
}
.ex_shop_header .header-lower .shop-category > ul>li:before {
    position: absolute;
    content: '';
    right: 0px;
    top: 0px;
    width: 0%;
    height: 100%;
    display: block;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
}
.ex_shop_header .header-lower .shop-category > ul>li>a {
    position: relative;
    display: block;
    padding: 17px 25px;
    border-bottom: 1px solid #e9e5df;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: #141417;
    font-family: 'Inter', sans-serif;
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}
.ex_shop_header .header-lower .shop-category > ul>li:last-child>a{
    border-bottom: none;
}
.ex_shop_header .header-lower .shop-category > ul>li.dropdown>a:after {
    position: absolute;
    content: "\f105";
    right: 15px;
    top: 18px;
    display: block;
    line-height: 24px;
    font-size: 17px;
    /* font-family: 'Font Awesome 5 Pro'; */
    font-family: 'fontawesome';
    font-weight: 400;
}
.ex_shop_header .header-lower .shop-category > ul>li>a i,
.ex_shop_header .header-lower .shop-category > ul>li>a i {
    margin-right: 10px;
    font-size: 23px;
    vertical-align: middle;
}
.ex_shop_header .header-lower .shop-category > ul>li>ul{
    position: absolute;
    left: 100%;
    top: 15px;
    width: 230px;
    background: #fff;
    box-shadow: 0 0px 30px 0px rgba(0,0,0,0.06);
    opacity: 0;
    visibility: hidden;
    transition: all 500ms ease;
}
.ex_shop_header .header-lower .shop-category > ul>li:hover>ul{
    visibility: visible;
    opacity: 1;
    top: 0px;
}
.ex_shop_header .header-lower .shop-category > ul>li> ul>li>a {
    position: relative;
    display: block;
    padding: 17px 23px;
    border-bottom: 1px solid #e9e5df;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
    color: #141417;
    font-family: 'Inter', sans-serif;
    text-align: left;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}
.ex_shop_header .header-lower .shop-category > ul>li> ul>li:last-child>a{
    border-bottom: none;
}
.ex_shop_header .header-lower .shop-category > ul>li> ul>li>a i,
.ex_shop_header .header-lower .shop-category > ul>li> ul>li>a i {
    margin-right: 10px;
    font-size: 23px;
    vertical-align: middle;
}
.ex_shop_header .header-lower .shop-category > a{
    background: #2B313E;
}  
.ex_shop_header .header-lower .shop-category > ul>li>a:hover{
    color: #E7131A;
}
.ex_shop_header .header-lower .shop-category > ul>li> ul>li>a:hover{
    color: #E7131A;
}

/* ================================
Header 3
================================== */
.header-style-three .header-upper .search-box {
    /* width: 565px; */
    background: #f5f5f5;
}
.header-style-three .header-upper .right-info li {
    color: #2B313E;
}
.header-style-three .header-upper .right-info li a {
    color: #2B313E;
}
.header-style-three .header-upper .right-info li a p {
    color: #2B313E;
}
.header-style-three  .search-box {
    max-width: 582px;
}
.header-style-three .search-box form {
    padding: 22px 20px 18px 48px;
    position: relative !important;
}
.header-style-three .header-upper .right-info li a i{
    position: relative;
}
.header-style-three .header-upper .right-info li a i .count {
    position: absolute;
    top: -6px;
    right: -6px;
    background: #E7131A;
    color: #fff;
    padding: 3px 6px;
    border-radius: 50%;
    font-size: 12px;
}
/* ================================
Header 3
================================== */


/* ================================
responsive
=================================== */
@media only screen and (max-width: 991px){
    .ex_shop_header .header-lower .shop-category > ul{
        position: absolute;
        left: 0px;
        top: 70px;
        width: 100%;
        background: #fff;
        box-shadow: 0 0px 30px 0px rgba(0,0,0,0.06);
        opacity: 0;
        visibility: hidden;
        -webkit-transform: scaleY(0);
        -moz-transform: scaleY(0);
        -ms-transform: scaleY(0);
        -o-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: top;
        -moz-transform-origin: top;
        -ms-transform-origin: top;
        -o-transform-origin: top;
        transform-origin: top;
        -webkit-transition: all 500ms ease;
        -moz-transition: all 500ms ease;
        -ms-transition: all 500ms ease;
        -o-transition: all 500ms ease;
        transition: all 500ms ease;
    }
    .ex_shop_header .header-lower .shop-category:hover > ul{
        opacity: 1;
        visibility: visible;
        -webkit-transform: scaleY(1);
        -moz-transform: scaleY(1);
        -ms-transform: scaleY(1);
        -o-transform: scaleY(1);
        transform: scaleY(1);
    }
}
@media only screen and (max-width: 991px){
    .ex_shop_header .header-lower .shop-category{
        max-width: 228px;
    }
}
/* ================================
responsive
=================================== */
