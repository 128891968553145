.owl-dot-style-one .owl-dots{
    margin-top: 20px;
}
.owl-dot-style-one .owl-dots .owl-dot span {
    background: #E0E0E0;
    display: block;
    margin: 0px 5px 0px 5px;
    transition: opacity 200ms ease 0s;
    width: 5px;
    height: 5px;
    border-radius: 50%;
}
.owl-dot-style-one .owl-dots .owl-dot.active span {
    background: none repeat scroll 0 0  #E7131A;
}

/* custom dot */
.dot {
    height: 5px;
    width: 5px;
    margin: 0 2px;
    background-color: #E0E0E0;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}
.dot.active{
    background: #E7131A;
}