/* =====================
banner one
======================== */
.banner-one{
    padding: 0 0;
}
.banner-left-container{
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    width: 100%;
    min-height: 615px;
    border-radius: 10px;
    z-index: -1;
    overflow: hidden;
}
.covina{
    background-image: url("https://tirezoneusa.com/Uploads/covina/covina.webp");
}
.glendora{
    background-image: url("https://tirezoneusa.com/Uploads/glendora/glendora.webp");
}
.ontario{
    background-image: url("https://tirezoneusa.com/Uploads/ontario/ontario.webp");
}
.whittier{
    background-image: url("https://tirezoneusa.com/Uploads/whittier/whittier.webp");
}
.montclair{
    background-image: url("https://tirezoneusa.com/Uploads/montclair/montclair.webp");
}

.covina_wholesale{
    background-image: url("https://ws.tirezoneusa.com/Uploads/Wholesale/covina/covina_wholesale.webp")!important;
}
.glendora_wholesale{
    background-image: url("https://ws.tirezoneusa.com/Uploads/Wholesale/glendora/glendora_wholesale.webp")!important;
}
.ontario_wholesale{
    background-image: url("https://ws.tirezoneusa.com/Uploads/Wholesale/ontario/ontario_wholesale.webp")!important;
}
.whittier_wholesale{
    background-image: url("https://ws.tirezoneusa.com/Uploads/Wholesale/whittier/whittier_wholesale.webp")!important;
}
.montclair_wholesale{
    background-image: url("https://ws.tirezoneusa.com/Uploads/Wholesale/montclair/montclair_wholesale.webp")!important;
}


.banner-content{
    height: fit-content;
    padding: 130px 86px;
}
.banner-content h6{
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    /* opacity: 1; */
    transform: translateY(50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .banner-content h6{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1000ms;
}
.banner-content h2{
    font-weight: 700;
    font-size: 48px;
    line-height: 57px;
    max-width: 500px;
    color: #FFFFFF;
    margin-top: 5px;
    margin-bottom: 20px;
    /* opacity: 0; */
    transform: translateY(50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .banner-content h2{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1500ms;
}
.btn-box{
    /* opacity: 0; */
    transform: translateY(50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .banner-content .btn-box{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 2000ms;
}
.banner-right-container{
    position: relative;
    /* background: #2B313E; */
    background: white;
    border-radius: 10px;
    height: 100%;
    padding: 40px;
}
.banner-right-container-top{
    display: flex;
    align-items: center;
    gap: 19px;
    margin-bottom: 30px;
}
.banner-right-title h6{
    font-size: 12px;
    line-height: 24px;
    /* color: #FFFFFF; */
    color: black;
    font-weight: 400;
}
.banner-right-title h5{
    font-size: 16px;
    line-height: 29px;
    /* color: #FFFFFF; */
    color: black;
    font-weight: 400;
}
.select-container .nice-select{
    margin-bottom: 15px;
}
.select-btn .theme-btn{
    margin-top: 15px;
}
.select-shape{
    position: absolute;
    right: 0;
    bottom: 0;
}
/* ======================
banner one
========================= */

/* ======================
banner two
========================= */
section.home-two-banner {
    position: relative;
    width: 100%;
    height: 650px;
}
.home-two-banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    transform: scale(1);
    transition: all 8000ms ease;
}
.banner-carousel .active .home-two-banner-bg{
    transform: scale(1.25);
    transition-delay: 1000ms;
}
.home-two-wrapper{
    width: 100%;
    height: 650px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-two-wrapper-inner{
    max-width: 641px;
    text-align: center;
}
.home-two-wrapper-inner h6{
    color: #FFF;
    font-size: 14px;
    padding: 10px 25px;
    background: #E7131A;
    border-radius: 5px;
    display: inline-block;
    opacity: 1;
    transform: translateY(-50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .home-two-wrapper-inner h6{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1000ms;
}
.home-two-wrapper-inner h2{
    color: #FFF;
    font-size: 48px;
    font-weight: 700;
    margin: 20px 0;
    line-height: 57px;
    opacity: 1;
    transform: translateX(-50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .home-two-wrapper-inner h2{
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1000ms;
}
.home-two-wrapper-inner p{
    color: #FFFFFF;
    opacity: 1;
    transform: translateX(50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .home-two-wrapper-inner p{
    opacity: 1;
    transform: translateX(0);
    transition-delay: 1000ms;
}
.btn-box{
    margin-top: 20px;
    opacity: 1;
    transform: translateY(50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .home-two-wrapper-inner .btn-box{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 1000ms;
}

/* auto parts */
.home4-auto-parts{
    background: #F7FAFF;
}
.auto-parts-container{
    background: #2B313E;
    border-radius: 10px;
    padding: 45px 94px;
    position: relative;
    margin-top: -80px;
    z-index: 2;
}
.auto-parts .select-container{
    display: flex;
    align-items: center;
    gap: 15px;
}
.auto-parts .nice-select {
    max-width: 226px;
    background: #383C45;
    border-radius: 0;
}
.auto-parts .select-container .nice-select {
    margin-bottom: 0px;
}
.auto-parts .select-btn .theme-btn {
    margin-top: 0px;
}
.auto-parts .btn-style-one {
    font-size: 12px;
    line-height: 24px;
    padding: 10px 10px;
}
/* auto parts */
/* =====================
home two
========================*/

/* =====================
home three
========================*/
.banner-three .banner-left-container{
    background-repeat: no-repeat;
    background-size: cover;
}
.banner-three .banner-content p{
    color: #FFFFFF;
    opacity: 1;
    transform: translateY(50px);
    transition: all 1000ms ease;
}
.banner-carousel .active .banner-content p{
    opacity: 1;
    transform: translateY(0);
    transition-delay: 2000ms;
}
.banner-three .banner-carousel .active .banner-content .btn-box{
    transition-delay: 2500ms;
}

.banner-three .banner-carousel .owl-dots {
    display: block;
    background: #ffffff;
    position: absolute;
    bottom: 40px;
    right: 40px;
    border-radius: 15px;
    padding: 1px 12px;
}
.banner-three .owl-theme .owl-dots .owl-dot span {
    background: #D9D9D9;
    display: block;
    margin: 0px 3px 0px 3px;
    transition: opacity 200ms ease 0s;
    width: 8px;
    height: 8px;
    border-radius: 50%;
}
.banner-three .owl-theme .owl-dots .owl-dot.active span {
    background: none repeat scroll 0 0 #E7131A;
    width: 10px;
    height: 10px;
}
/* =====================
home three
========================*/

/* =====================
home four
========================*/
.home-four-banner{
    margin-top: -50px;
}
/* =====================
home four
========================*/

/* responsive */
@media only screen and (max-width: 1366px){
    .auto-parts .nice-select {
        max-width: 218px;
    }
    .auto-parts-container {
        padding: 45px 60px;
    }
}

@media only screen and (max-width: 1024px){
    .auto-parts .nice-select {
        max-width: 177px;
    }
    .auto-parts-container {
        padding: 45px 37px;
    }
}

@media only screen and (max-width: 768px){
    .auto-parts-container {
        position: relative;
    }
    .select-btn {
        /* position: absolute !important; */
        /* top: 45px !important;
        right: 37px !important; */
    }
}

@media only screen and (min-width: 360px) and (max-width: 740px){
    section.home-two-banner {
        height: 550px;
    }
    .home-two-wrapper {
        height: 550px;
    }
    .home-two-wrapper-inner h2 {
        font-size: 38px;
        line-height: 49px;
    }
    .auto-parts-container {
        position: relative;
        height: 480px;
    }
    .select-btn {
        display: inline-block;
        position: inherit !important;
        margin-top: 10px;
        width: 100%;
        text-align: center;
    }
    .auto-parts .select-container {
        display: block;
    }
    .auto-parts .nice-select {
        max-width: 100%;
        margin: 10px 0;
    }
    .nice-select .list {
        width: 100%;
        border-radius: 0;
    }
    
}

