textarea {
    overflow: hidden;
}
button {
    outline: none !important;
    cursor: pointer;
}
::-webkit-input-placeholder {
    color: inherit;
}
::-moz-input-placeholder {
    color: inherit;
}
::-ms-input-placeholder {
    color: inherit;
}
.owl-carousel img {
    width: auto !important;
    display: inline-block !important;
}
.owl-carousel .owl-item {
    opacity: 0;
}
.owl-carousel .owl-item.active {
    opacity: 1;
}
.btn-light:not(:disabled):not(.disabled).active:focus, 
.btn-light:not(:disabled):not(.disabled):active:focus, 
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: none;
    outline: none;
}
.btn-light:not(:disabled):not(.disabled).active, 
.btn-light:not(:disabled):not(.disabled):active, 
.show>.btn-light.dropdown-toggle {
    background-color: inherit;
    border-color: inherit;
    color: inherit;
    border-radius: 0;
}
.bootstrap-select .dropdown-menu li a span.text {
    margin-bottom: 0;
}
.bootstrap-select .dropdown-menu li.active a span.text {
    color: #fff;
}
.bootstrap-select .dropdown-toggle .filter-option:after {
    font-family: 'fontawesome';
    content: "\f107";
    position: absolute;
    right: 15px;
    top: 7px;
    display: block;
    line-height: 30px;
    font-size: 17px;
    text-align: center;
    z-index: 5;
    font-weight: 400;
    color: #fff;
}
.dropup .dropdown-toggle::after {
    display: none;
}
.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    z-index: 9;
    overflow: hidden;
}
.auto-container {
    position: static;
    /* max-width: 1300px; */
    padding: 0px 25px;
    /* margin: 0 auto; */
}
ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}
figure {
    margin-bottom: 0;
}
.theme-btn {
    display: inline-block;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.centered {
    text-align: center !important;
}
.gray-bg {
    background-color: #f4f4f4 !important;
}
.light-bg {
    background-color: #fff !important;
}
img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.dropdown-toggle::after {
    display: none;
}
.fa {
    line-height: inherit;
}
.owl-nav,
.owl-dots {
    display: none;
}
/* Btn style */
.theme-btn {
    display: inline-block;
    transition: all .5s ease;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
}
.mb-20 {
    margin-bottom: 20px !important;
}
.mb-30 {
    margin-bottom: 30px !important;
}
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.mt-70 {
    margin-top: 70px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}
.mb-70 {
    margin-bottom: 70px !important;
}
.pb-20 {
    padding-bottom: 20px !important;
}
.pb-30 {
    padding-bottom: 30px !important;
}
.pb-50 {
    padding-bottom: 50px !important;
}

/*Btn Style One*/
.btn-style-one {
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 5px;
    overflow: hidden;
    text-transform: capitalize;
    vertical-align: middle;
    z-index: 1;
}
.btn-style-one.style-two {
    background-color: #fff;
    color: #222 !important;
}
.btn-style-one.style-two:hover{
    background-color: var(--theme-color);
}
.btn-style-one i {
    margin-left: 8px;
    display: inline-block;
    position: relative;
    transition: .5s;
    font-size: 12px;
    vertical-align: middle;
    position: relative;
}
.btn-style-one:hover {
    color: #fff !important;
}
.btn-style-one:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    content: "";
    background-color: #2B313E;
    transition: .5s;
    z-index: -1;
}
.btn-style-one:hover:before {
    width: 100%;
}
.btn-style-one span {
    position: relative;
}

.btn-two{
    position: relative;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    background-color: #2B313E;
    color: #FFFFFF;
    padding: 5px 10px;
    border-radius: 5px;
    overflow: hidden;
    text-transform: capitalize;
    vertical-align: middle;
    z-index: 1;
}
.btn-two i {
    margin-left: 8px;
    display: inline-block;
    position: relative;
    transition: .5s;
    font-size: 12px;
    vertical-align: middle;
    top: 2px;
}
.btn-two:hover {
    color: #fff !important;
}
.btn-two:before {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0%;
    content: "";
    background-color: #E7131A;
    transition: .5s;
    z-index: -1;
}
.btn-two:hover:before {
    width: 100%;
}
.nice-select{
    position: relative;
    display: block;
    width: 100%;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
    font-weight: 500;
    color: #383C45;
    background: white;
    border: 1px solid #383C45;
    border-radius: 5px;
    padding: 0px 20px;
}
.checkout-section .nice-select, .short-box .nice-select{
    background: transparent;
}
.nice-select:after{
    position: absolute;
    content: "\f107";
    /* font-family: 'Font Awesome 5 Pro'; */
    font-family: 'fontawesome';
    font-size: 12px;
    color: #B8B8B8;
    top: 0px;
    right: 30px;
    margin: 0px;
    margin: 0px;
    border: none !important;
    transform: rotate(0deg) !important;
} 

/* common section title */
.section-title{
    display: flex;
    align-items: center;
    gap: 12px;
}
.section-title h4{
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    color: #2B313E;
}
.common-section-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 25px;
    border-bottom: 1px solid #E9ECEF ;
    margin-bottom: 40px;
}
.common-section-title-left{
    display: flex;
    align-items: center;
    gap: 40px;
}
/* common section title */

/* common more button */
.common-more-btn a{
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2B313E;
    transition: 0.5s;
}
.common-more-btn a i{
    margin-bottom: -5px;
    margin-left: 10px;
}
.common-more-btn a:hover{
    color: #E7131A;
}
/* common more button */


    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }


    @media (max-width: 768px) {
        .blink {
          font-size: 1rem; 
        }
      }
      
      @media (max-width: 480px) {
        .blink {
          font-size: 0.8rem!important; 
        }
      }
      @keyframes jump {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-10px); }
      }
      
      .icon-shopping-cart.jumping {
        display: inline-block;
        animation: jump 0.8s ease-in-out infinite;
      }

      .locationPhone{
        display: flex;
        align-items: center;
        margin: 0;
        padding-right: 20px;
        color: black;
        font-weight: bold;
        font-size: 14pt;
      }
      
      @media (max-width: 1400px) {
        .locationPhone {
          font-size: 14pt;
        }
      }


    @media (min-width: 768px) { 
        .sidebar {
            height: 100vh!important; 
            overflow: auto!important;
            position: sticky!important;
            top: 100px; 
            background-color: white;
        } 
        body{
            overflow:visible!important;
        }
    }
   