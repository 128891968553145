/* Main footer One */
.main-footer-one {
    position: relative;
    background-color: #2B313E;
}
.main-footer-one .outer-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.main-footer-one .upper-box {
    padding: 27px 0;
    border-bottom: 1px solid rgb(255 255 255 / 5%);
}
.main-footer-one .outer-box{
    justify-content: center;
    gap: 86px;
}
.main-footer-one .lower-box {
    padding-top: 63px;
    padding-bottom: 30px;
}
.footer-logo-widget .footer-logo{
    margin-bottom: 30PX;
}
.footer-logo-widget p{
    font-size: 15px;
}
.main-footer-one ul.social-icon {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
}
.main-footer-one ul.social-icon li + li {
    margin-left: 15px;
}
.main-footer-one ul.social-icon li a {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 1px solid #454A53;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    color: #fff;
    transition: .5s;
}
.main-footer-one ul.social-icon li a:hover {
    background-color: var(--theme-color);
}

/* Footer Widget */
.footer-widget {
    position: relative;
}

/* Footer Widget Title */
.footer-widget .footer-widget-title {
    position: relative;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: #ffffff;
}

.footer-widget a:hover{
    color: #E7131A;
}

/* Footer About Widget */
.footer-about-widget {
    position: relative;
}
.footer-about-widget .logo {
    position: relative;
    margin-bottom: 45px;
}
.footer-about-widget .text {
    position: relative;
    color: rgb(255 255 255 / 0.6);
    margin-bottom: 35px;
}
.footer-about-widget .social-icon {
    position: relative;
}
.footer-about-widget .social-icon li {
    display: inline-block;
    margin-right: 18px;
}
.footer-about-widget .social-icon li a {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    color: rgb(255 255 255 / 0.6);
    border: 1px solid rgba(213, 213, 213, 0.2);
    transition: .5s;
}

/* Footer links Widget */
.footer-links-widget li {
    position: relative;
    margin-bottom: 13px;
    font-size: 16px;
}
.footer-links-widget li:last-child {
    margin-bottom: 0;
}
.footer-links-widget li a {
    transition: .5s;
    font-size: 15px;
    line-height: 29px;
    color: #8B929A;

}

/* Footer Services links Widget */
.footer-services-links-widget li {
    position: relative;
    margin-bottom: 12px;
    font-size: 16px;
}
.footer-services-links-widget li:last-child {
    margin-bottom: 0;
}
.footer-services-links-widget li a {
    transition: .5s;
    font-size: 15px;
    line-height: 29px;
    color: #8B929A;

}

/* Footer Contact Widget */
.footer-contact-widget .single-contact-info {
    position: relative;
    margin-bottom: 40px;
}
.footer-contact-widget .single-contact-info:last-child {
    margin-bottom: 0;
}
.footer-contact-widget h5 {
    position: relative;
    font-size: 16px;
    color: rgb(255 255 255 / 0.6);
    margin-bottom: 15px;
}
.footer-contact-widget ul li a, 
.footer-contact-widget ul li {
    position: relative;
    font-size: 15px;
    line-height: 29px;
    color: #8B929A;
    font-weight: 400;
    margin-bottom: 12px;
}
.footer-contact-widget ul li a {
    color: #8B929A;
    transition: .5s;
}
.footer-widget .footer-contact-info{
    display: inline-block;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 0px;
}


.footer-locations-div{
    display: grid;
    grid-template-columns: repeat(4, minmax(20%, 1fr));
    grid-gap: 20px;
}

.multiToggle_grid{
    display: grid;
    grid-template-columns: repeat(2, minmax(50%, 1fr));
    grid-gap: 5px;
}

.footer-locations-div ul{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* height: 100%; */
    /* margin-top: 10px; */
}


@media only screen and (max-width: 768px){
    .footer-services-links-widget,.footer-contact-widget{
        margin-top: 50px;
    }
    .footer-locations-div{
        grid-template-columns: repeat(1, minmax(100%, 1fr));
        grid-gap: 20px;

    }
    .multiToggle_grid{
        grid-template-columns: repeat(1, minmax(100%, 1fr));
        grid-gap: 0;
    }
}

@media only screen and (min-width: 360px) and (max-width: 740px){
    .main-footer-one .outer-box {
        justify-content: center;
        gap: 0;
    }
    .main-footer-one ul.social-icon {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 25px;
    }
    .footer-links-widget{
        margin-top: 50px;
    }
    
}
@media only screen and (min-width: 1025px) and (max-width: 1520px){
    .footer-locations-div{
        display: grid;
        grid-template-columns: repeat(3, minmax(20%, 1fr));
        grid-gap: 20px;
    }
}
@media only screen and (min-width: 740px) and (max-width: 1024px){
    .footer-locations-div{
        display: grid;
        grid-template-columns: repeat(2, minmax(25%, 1fr));
        grid-gap: 20px;
    }
}
