@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?rq96ib');
  src:  url('../fonts/icomoon.eot?rq96ib#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?rq96ib') format('truetype'),
    url('../fonts/icomoon.woff?rq96ib') format('woff'),
    url('../fonts/icomoon.svg?rq96ib#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-call:before {
  content: "\e900";
}
.icon-car-battery:before {
  content: "\e901";
}
.icon-car-insurance:before {
  content: "\e902";
}
.icon-heart:before {
  content: "\e904";
}
.icon-left-right-arrow:before {
  content: "\e905";
}
.icon-magnifing-glass:before {
  content: "\e906";
}
.icon-menu-bar:before {
  content: "\e907";
}
.icon-open-eye:before {
  content: "\e908";
}
.icon-repair:before {
  content: "\e909";
}
.icon-scooter:before {
  content: "\e90a";
}
.icon-shock-absorber:before {
  content: "\e90b";
}
.icon-shopping-cart:before {
  content: "\e90c";
}
.icon-tair:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90f";
}
