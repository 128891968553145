.catagory{
    padding: 90px 0;
}
.categories-content{
    width: 100%;
    height: 278px;
    border-radius: 10px;
    border: 1px solid #E9ECEF;
    margin-bottom: 33px;
}
.flip-card {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px;
}
.flip-card img{
    width: 214px;
    width: 164px;
}
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}
.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}
.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.flip-card-front {
    color: #2B313E;
}
.flip-card-back {
    color: #2B313E;
    transform: rotateY(180deg);
}
.category-content-top{
    position: relative;
    width: 100%;
    height: 199px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    margin-bottom: 33px;
}
.category-content-top p{
    color: #FFF;
    font-size: 10px;
}
.category-content-top h4{
    color: #FFF;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 2.6px;
}
.category-content-top h2{
    color: #FFF;
    font-size: 48px;
    font-weight: 500;
    line-height: 50px;
    display: flex;
    align-items: center;
}
.category-content-top h2 span{
    display: inline-block;
    color: #FFF;
    font-size: 25px;
    font-family: Poppins;
    font-weight: 500;
    text-transform: uppercase;
}
.category-content-top a{
    display: inline-block;
    color: #FFF;
    font-size: 12px;
    line-height: 24px;
    text-decoration-line: underline;
}
.category-content-bottom {
    position: relative;
    width: 100%;
    height: 356px;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
}
.category-image-content {
    position: absolute;
    left: 27px;
    bottom: 27px;
}
.category-image-content h5{
    color: #FFF;
    font-size: 18px;
    font-weight: 700;
    margin: 15px 0;
}